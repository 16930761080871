import _uniqueId from "lodash/uniqueId";
import { useRouter } from 'next/router';
import React, { useState, useContext, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { currentTime } from "../../utils";
import range from "lodash/range";


export const SelectedItemsContext = React.createContext({
    selectedItems: [],
    addSelectedItem: () => { },
    removeSelectedItem: () => { },
    removeAllSelectedItems: () => { },
});

export const SelectedItemsStorage = ({ children }) => {
    const [selectedItems, setSelectedItems] = useState([]);

    const addSelectedItem = (itemId) => {
        if (selectedItems.indexOf(itemId) === -1) {
            setSelectedItems([...selectedItems, itemId]);
        }
    };
    const removeSelectedItem = (itemId) => {
        setSelectedItems(selectedItems.filter((i) => i.id !== itemId));
    };

    const removeAllSelectedItems = () => {
        setSelectedItems([]);
    };

    return (
        <SelectedItemsContext.Provider
            value={{
                addSelectedItem,
                removeSelectedItem,
                selectedItems,
                removeAllSelectedItems,
            }}
        >
            {children}
        </SelectedItemsContext.Provider>
    );
};


export const BubblesContext = React.createContext({
    bubbles: [],
    addBubble: () => { },
    activeRecipeId: null,
    setActiveRecipeId: () => { }
});

export const BubblesContextStorage = ({ children, initialActiveRecipeId }) => {
    const [bubbles, setBubbles] = useState([]);
    const [activeRecipeId, setActiveRecipeId] = useState(initialActiveRecipeId);

    const addBubble = (bubble) => {
        const _id = _uniqueId("bubble-");
        setBubbles(b => [...b, { ...bubble, id: _id }]);
    };

    useEffect(() => {
        if (initialActiveRecipeId) {
            setActiveRecipeId(initialActiveRecipeId);
        }
    }, [initialActiveRecipeId]);

    return (
        <BubblesContext.Provider
            value={{
                bubbles,
                addBubble,
                activeRecipeId,
                setActiveRecipeId
            }}
        >
            {children}
        </BubblesContext.Provider>
    );
};



export const TimersContext = React.createContext({
    addTimer: () => { },
    addTimerFromTimerToken: () => { }
});

export const TimersContextStorage = ({ children }) => {
    const context = useContext(BubblesContext);
    const router = useRouter();

    const addTimer = ({ duration, alerts, text, recipeId }) => {
        const id = uuidv4();
        const timer = { id, duration, alerts, text, recipeId, fullDuration: duration, target: null, path: router.asPath, title: document.title };
        localStorage.setItem(`timer_${id}`, JSON.stringify(timer));
        context.addBubble({ type: 'timer', timerId: id });
    };

    const addTimerFromTimerToken = ({ token, recipeId }) => {
        let timeMultiplier = {
            'h': 60 * 60,
            'm': 60,
            's': 1
        }[token.value[1]];

        let duration = 0;
        let alerts = [];
        if (token.value[0].type === "number" || token.value[0].type === "number.text" || token.value[0].type === "number.fraction") {
            duration = Math.floor(token.value[0].value[0] * timeMultiplier);
        }
        else if (token.value[0].type === "range") {
            duration = Math.floor(token.value[0].value[1].value[0] * timeMultiplier);

            const alertAfterTime = token.value[0].value[0].value[0];
            const alertAfter = Math.floor(alertAfterTime * timeMultiplier);
            const alertDelta = duration - alertAfter;
            const alertDivider = alertDelta >= 60 * 60 ? 4 : 10;
            const alertSnoozeGap = Math.round(Math.min(Math.max(alertDelta / alertDivider, 60 * 3), 15 * 60));
            alerts = range(alertAfter, duration, alertSnoozeGap);

        }

        addTimer({ duration, alerts, text: token.text, recipeId: recipeId });
    }

    useEffect(() => {
        const localTimers_ = Object.keys(localStorage).filter(k => k.startsWith('timer_')).reduce(function (list, str) {
            list.push(str.slice(6));
            return list
        }, []);
        localTimers_.map(timerId => context.addBubble({ type: 'timer', timerId: timerId }));
    }, []);

    return (
        <TimersContext.Provider
            value={{
                addTimer,
                addTimerFromTimerToken
            }}
        >
            {children}
        </TimersContext.Provider>
    );
};

