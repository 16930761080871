import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import React from "react";

export const InputError = ({ error, className }) => (
    <>
        {error && (
            <div className={`text-sm text-red-500 flex items-center mt-2 ${className}`}
            >
                <ExclamationCircleIcon
                    className={`h-5 w-5 mr-1 text-red-500`}
                />
                <div>{error}</div>
            </div>
        )}
    </>
);

InputError.defaultProps = {
};
