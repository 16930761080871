import React, { useEffect, useRef, useState } from "react";
import { InputError } from "./InputError";


const _Input = ({ id, label, value, error, showError, help, className, autoComplete, ...other }, ref) => {
    const errorStyles = error ? "bg-red-50 border-red-50 focus:border-red-200 placeholder-red-500" : "";
    const bgColor = value ? "bg-gray-50" : "bg-gray-100";

    return (
        <>
            {label && (
                <label htmlFor={id} className="block mb-1">
                    <span className="text-gray-700">{label}</span>
                </label>
            )}
            <input
                id={id}
                value={value}
                {...other}
                type="text"
                ref={ref}
                autoComplete={autoComplete}
                className={`block w-full rounded-md ${bgColor} focus:border-gray-300 hover:bg-gray-50 border-transparent focus:bg-white focus:shadow-sm transition duration-500 ease-in-out  focus:ring-0 ${className} ${errorStyles}`}
            />
            {showError && <InputError error={error} />}
            {help && <p className="mt-2 text-sm text-gray-500">{help}</p>}
        </>
    );
}

export const Input = React.forwardRef(_Input);

Input.defaultProps = {
    autoComplete: "off",
    showError: true,
};

export const _InputWithContext = ({ id, label, value, error, showError, help, className, autoComplete, context, position, ...other }, ref) => {
    const errorStyles = error ? "bg-red-50 border-red-50 focus:border-red-200 placeholder-red-500" : "";
    const bgColor = value ? "bg-gray-50" : "bg-gray-100";
    const contextRef = useRef(null);
    const [contextWidth, setContextWidth] = useState(10);

    useEffect(() => {
        if (contextRef.current) {
            setContextWidth(contextRef.current.getBoundingClientRect().width + 4);
        }

    }, [contextRef]);

    return (
        <>
            {label && (
                <label htmlFor={id} className="block mb-1">
                    <span className="text-gray-700">{label}</span>
                </label>
            )}
            <div className="relative rounded-md focus:shadow-sm w-full">
                <input
                    id={id}
                    value={value}
                    {...other}
                    type="text"
                    ref={ref}
                    autoComplete={autoComplete}
                    className={`block w-full rounded-md ${bgColor} focus:border-gray-300 hover:bg-gray-50 border-transparent focus:bg-white focus:shadow-sm transition duration-500 ease-in-out focus:ring-0 ${className} ${errorStyles}`}
                    style={{ [position === "left" ? "paddingLeft" : "paddingRight"]: `${contextWidth}px` }}
                />
                <div className={`absolute inset-y-0 ${position === "left" ? "left-0 pr-1 pl-3" : "right-0 pl-1 pr-3"} flex items-center pointer-events-none`} ref={contextRef}>
                    <span className="text-gray-500 sm:text-sm">
                        {context}
                    </span>
                </div>
            </div>
            {showError && <InputError error={error} />}
            {help && <p className="mt-2 text-sm text-gray-500">{help}</p>}
        </>
    );
};
export const InputWithContext = React.forwardRef(_InputWithContext);


