import React from "react";
import { Spinner } from "../Spinner";

export const Button = React.forwardRef(({
    children,
    onClick,
    className,
    loading,
    disabled,
    size,
    href,
    spinner,
    as,
    ...other
}, ref) => {

    const sizeClass = {
        xs: "px-2.5 py-1.5 text-xs rounded",
        sm: "px-3 py-2 text-sm leading-4 rounded-md",
        md: "px-4 py-2 text-sm rounded-md",
        lg: "px-4 py-2 text-base rounded-md",
        xl: "px-6 py-3 text-base rounded-md",
        null: "",
    }[size];

    const Type = as || (href === undefined ? "button" : "a");
    const extra = href === undefined ? { type: "button" } : { href };
    return (
        <Type
            {...extra}
            {...other}
            disabled={disabled || loading}
            onClick={onClick}
            ref={ref}
            className={`inline-flex border items-center font-medium focus:outline-none focus:ring-1 focus:ring-offset-2 ring-gray-200 disabled:opacity-50 transition duration-150 ease-in-out ${sizeClass} ${className}`}
        >
            {loading && (spinner || <Spinner />)}
            {children}
        </Type>
    );
});

Button.defaultProps = {
    size: "md",
    loading: false,
    spinner: null,
    as: null
};

export const BorderlessButton = React.forwardRef(({ className, ...other }, ref) => {
    return (
        <Button
            className={`text-gray-900 border-transparent hover:border-gray-200 ${className}`}
            ref={ref}
            {...other}
        />
    );
});
BorderlessButton.displayName = "BorderlessButton";

export const SecondaryButton = React.forwardRef(({ className, ...other }, ref) => {
    return (
        <Button
            className={`text-gray-400 bg-gray-100 hover:bg-gray-500 shadow-sm ${className}`}
            ref={ref}
            {...other}
        />
    );
});
SecondaryButton.displayName = "SecondaryButton";

export const WhiteButton = React.forwardRef(({ className, ...other }, ref) => {
    return (
        <Button
            className={`border-gray-300 text-gray-700 bg-white hover:text-gray-500 focus:ring-gray-300 shadow-sm ${className}`}
            ref={ref}
            {...other}
        />
    );
});
WhiteButton.displayName = "WhiteButton";

export const PrimaryButton = React.forwardRef(({ className, ...other }, ref) => {
    return (
        <Button
            className={`text-white border-gray-900 bg-gray-900 hover:bg-gray-800 focus:ring-gray-900 shadow-sm ${className}`}
            ref={ref}
            {...other}
        />
    );
});
PrimaryButton.displayName = "PrimaryButton";


export const PrimaryButtonOverDark = React.forwardRef(({ className, ...other }, ref) => {
    return (
        <Button
            className={`text-white bg-gray-800 hover:bg-gray-700 focus:ring-gray-800 shadow-sm ${className}`}
            ref={ref}
            {...other}
        />
    );
});
PrimaryButtonOverDark.displayName = "PrimaryButtonOverDark";
